/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import MyModal from "../UI/MyModal/MyModal";
import Alert from 'react-bootstrap/Alert';
import AssetsList from "./AssetsList";
import Button from "react-bootstrap/esm/Button";
import Auth from "../../API/Auth"
import AddAssetForm from "./AddAssetForm";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import SandboxService from "../../API/SandboxService";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SandBoxList from "./SandBoxList";
import PatchAssetForm from './PatchAssetForm'
import bolt from '../../icons/bolt.svg';
import failed from '../../icons/error_icon.svg';
import pending from '../../icons/pending.svg';
import requests from '../../icons/requests.svg';
import forti from '../../icons/fortinet.svg'
import palo from '../../icons/PANW.svg'
import clam from '../../icons/Clam.png'
import kasper from '../../icons/kasper.svg'
import kasper_atap from '../../icons/rs-b-kata.png'
import danger from '../../icons/danger.svg'
import success from '../../icons/success.svg'
import FilesList from "./FilesList";
import RequestsList from "./RequestsList";
import OrgService from "../../API/OrgService";
import chkp from '../../icons/CHKP.svg'
import LicenseList from "./LicenseList";
import SignaturesList from "./SignaturesList";
import { getHourDate } from "./utils";
function SandboxArea() {
    const [errors, setErrors] = useState([]);
    const [page, setPage] = useState(0)
    const [assets, setAssets] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [platforms, setPlatforms] = useState([]);
    const [switchActive, setSwitchActive] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(false);
    const [patchAsset, setPatchAsset] = useState(false);
    const [addInstance, setAddInstance] = useState(false);
    const [sandboxStatistic, setSandboxStatistic] = useState(false)
    const [key, setKey] = useState('firewall');
    const [allOrgs, setAllOrgs] = useState([])
    const [infoLoader, setInfoLoader] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [orgFilter, setOrgFilter] = useState(false);
    const [ordering, setOrdering] = useState("-last_active");
    const [type, setType] = useState(false);
    const [activeFilter, setActiveFilter] = useState(false)
    const [registeredFilter, setRegisteredFilter] = useState(false);
    const [connectFilter, setConnectFilter] = useState(false);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const [today, setToday] = useState(false)
    const appletStyleStats = {
         'padding': '0', 'borderRadius': '10px', 'position': "relative", 'boxSizing': "border-box",

        'WebkitBoxSizing': 'border-box', minHeight: '600px',

    }
    const fetchStatistic = async (repeat=false)=> {
        setInfoLoader(true)
        await OrgService.getDropList().then((resp)=>{setErrors([]); setAllOrgs(resp); setIsPreloader(false)} ).catch((e) => { changeStatus(); setIsPreloader(false); setErrors(e?.message || ['Ошибка сервера'] ); });
            
        await SandboxService.getSandboxStatistic(repeat).then((resp) => { setSandboxStatistic(resp); setErrors([]); setInfoLoader(false) }).catch((e) => { changeStatus(); setInfoLoader(false); setErrors(e?.message || ['Ошибка сервера'] ); });
    }
    const fetchData = async ()=> {
         setIsPreloader(true);
         let filters = false;
        if (
            searchText ||
           
            ordering || 
           
            orgFilter || type || activeFilter || connectFilter || registeredFilter
        ) {
            filters = {
                type__in: type?.key,
                search: searchText,
                organisation: orgFilter?.id,
                ordering: ordering,
                is_active: connectFilter,
                is_registered: registeredFilter,
                last_active__gte: activeFilter? getHourDate() : null


            };
        }
         await SandboxService.getAssetsList(false, filters).then((resp) => { setAssets(resp); setErrors([]); setIsPreloader(false) }).catch((e) => { changeStatus(); setIsPreloader(false); setErrors(e?.message || ['Ошибка сервера'] ); });
    }
    useEffect(() => {
        (async () => {
            if (switchActive === false && deleteItem === false && addInstance === false && patchAsset===false ) {
               
                fetchStatistic()
               
            }
        })()
    }, [switchActive, deleteItem, addInstance, patchAsset]);

    useEffect(() => {
        setIsPreloader(true);
        const timerRefresh = setTimeout(fetchData, 500);
        return () => {
            clearTimeout(timerRefresh);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, orgFilter, type, ordering, switchActive, deleteItem, addInstance, patchAsset,activeFilter, registeredFilter, connectFilter])
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'delete') deleteAsset(currentItem.id)
                else switchActivationAsset(currentItem)
            })
            .catch(() => changeStatus())
    }

    const handleClick = (index) => {

        if (index === 0) setPage(0)
        else setPage(index)

    }

    const newPaginationString = (count) => {
        let paginationArr = []
        let arrPages = [];
        let allPages = Math.floor(count / 20);
        if (count % 20 === 0) allPages = Math.floor(count / 20) - 1;
        for (let i = 0; i <= allPages; i++) { arrPages.push(i) };
        if (count) {
            if (allPages <= 10)
                return arrPages.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => handleClick(index)} >{index + 1}</button></li>)
            else if (page < 7 && allPages > 10) {
                paginationArr = arrPages.slice(0, 8); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else if (page > arrPages.length - 7 && allPages > 10) {
                paginationArr = arrPages.slice(arrPages.length - 8, arrPages.length); paginationArr.unshift(0, '...'); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else { paginationArr = arrPages.length > 0 && arrPages.slice(page - 3, page + 4); paginationArr.unshift(0, '...'); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>) }
        }
    }

    const switchActivationAsset = async (item) => {

        setIsPreloader(true);
        let param = !item.is_active
        await SandboxService.switchActivate(item.id, param)
            .then(() => { setIsPreloader(false); setSwitchActive(false) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('put');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }
    const deleteAsset = async (id) => {
        setIsPreloader(true);
        await SandboxService.deleteSerial(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }

    return (
        <Col style={{paddingLeft: 0}}>
 
            {switchActive && <MyConfirm visible={switchActive} setVisible={setSwitchActive} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите {currentItem.is_active === false ? 'активировать' : 'деактивировать'} {<br />}устройство {currentItem.serial}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); switchActivationAsset(currentItem) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setSwitchActive(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            {deleteItem && <MyConfirm visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить доступ{<br />}устройству {currentItem.serial}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteAsset(currentItem.id) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}


            <Row> {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }</Row>

            <Row className='justify-content-between mt-3 mb-4' >
         

                <Col className='col-md-auto'><h3>SANDBOX</h3>
                <div style={{display: "flex", gap: 5, color: '#0D6EFD'}}>
                    <span style={{textDecoration: !today? 'underline': '', cursor: "pointer"}} onClick={()=> setToday(false)}>сегодня</span><div style={{height: 20, width: 2, background: '#0D6EFD'}}></div><span onClick={()=> setToday(true)}style={{textDecoration: today? 'underline': '', cursor: "pointer"}}>все время</span></div>
                    </Col> 
                <Col className="col-md-auto" style={{position: 'relative'}}>
                {infoLoader && <Preloader leftCoord={true} />}
                    <div style={{ display: "flex", gap: '10px', justifyContent: "flex-end"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: 5,  marginTop: 26}}>
                    <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',  alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={bolt} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{sandboxStatistic?.firewalls_registered|| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Firewalls registered</span></div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={danger} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px', }}><span style={{color: sandboxStatistic?.checked_malware_requests_any_sandbox_total?"red": 'black'}}>{sandboxStatistic?.checked_malware_requests_any_sandbox_total|| '0'}</span>
                                <span style={{ fontSize: '10px' , fontWeight: 400}}>Total unique malware {<br/>}files in database</span></div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100px'}}><span>{sandboxStatistic?.sandboxes_alive_palo_alto|| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Sandboxes alive</span>
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={forti} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100px'}}><span>{sandboxStatistic?.sandboxes_alive_forti|| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Sandboxes alive</span>
                                </div>
  
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={chkp} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100px'}}><span>{sandboxStatistic?.sandboxes_alive_checkpoint| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Sandboxes alive</span>
                                </div>
  
                        </div>
                        <div><Button style={{width: '100%', height: 49}}variant="outline-primary" onClick={()=> fetchStatistic(true)}>Обновить</Button></div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", gap: 5,marginTop: 26}}>
                        <div style={{ height: 50, padding: '5px 10px 3px 10px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={requests} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{sandboxStatistic?.requests_today_total|| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Requests today all</span></div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={danger} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px', }}><span style={{color: sandboxStatistic?.requests_today_malware?"red": 'black'}}>{sandboxStatistic?.requests_today_malware|| '0'}</span>
                                <span style={{ fontSize: '10px' , fontWeight: 400}}>Today malware {<br/>}verdicts</span></div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px 10px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={success} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{((sandboxStatistic?.new_verdicts_today_db  || '0')+ ' / ' + (sandboxStatistic?.new_verdicts_today|| '0'))}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today {<br/>}DB / NEW</span></div>
                        </div>

                        <div style={{ height: 50, padding: '5px 10px 3px 10px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={pending} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{sandboxStatistic?.requests_total_pending|| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Requests processing</span></div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px 10px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={failed} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{sandboxStatistic?.requests_today_failed|| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Failed</span></div>
                        </div>
                        {/* <div style={{ height: 50, padding: '5px 10px 3px 10px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} title='Firewalls - number of devices that have been active during the last 60 minutes'className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={pending} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{sandboxStatistic?.requests_total_pending|| '0'}</span>
                                <span style={{ fontSize: '10px', fontWeight: 400 }}>Requests total {<br/>}processing</span></div>
                        </div> */}
                        </div>
                        
                        {/* <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                        <span style={{textAlign: "center", fontSize: 14}}>Verdicts from PARUS db</span>    
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px',   }}><span>{(!today? sandboxStatistic?.palo_db_verdicts_today :  sandboxStatistic?.palo_db_verdicts_total) || '0'}</span>
                                
                                </div>
                        </div>
                        
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={forti} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.forti_db_verdicts_today  :  sandboxStatistic?.forti_db_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>
               
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={kasper} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{(!today? sandboxStatistic?.ktip_db_verdicts_today  :  sandboxStatistic?.ktip_db_verdicts_total) || '0'}</span>
                             
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                        <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }<span style={{fontSize: 10}}>TV</span></div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.tv_db_verdicts_today :  sandboxStatistic?.tv_db_verdicts_total) || '0'}</span>
                           
                                </div>
                        </div>
                        </div> */}
                        <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                        <span style={{textAlign: "center", fontSize: 14}}>Verdicts from audit</span>    
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.palo_unique_verdicts_today  :  sandboxStatistic?.palo_unique_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                        </div>
                        
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={forti} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.forti_unique_verdicts_today   :  sandboxStatistic?.forti_unique_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>
                                Verdicts today / all</span> */}
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={kasper} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{(!today? sandboxStatistic?.ktip_unique_verdicts_today  :  sandboxStatistic?.ktip_unique_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Checked hashes today / all</span> */}
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }<span style={{fontSize: 10}}> TV</span></div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.tv_unique_verdicts_today :  sandboxStatistic?.tv_unique_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                        </div>
                        {/* <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={kasper_sandbox} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.kasper_sb_unique_verdicts_today :  sandboxStatistic?.kasper_sb_unique_verdicts_total) || '0'}</span>
                 
                                </div>
                        </div> */}
                                            <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={kasper_atap} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.kaspersky_atap_unique_verdicts_today :  sandboxStatistic?.kaspersky_atap_unique_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                                
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={chkp} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.checkpoint_unique_verdicts_today :  sandboxStatistic?.checkpoint_unique_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                                
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={clam} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(!today? sandboxStatistic?.clamav_unique_verdicts_today :  sandboxStatistic?.clamav_unique_verdicts_total) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                                
                        </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                        <span style={{textAlign: "center", fontSize: 14}}>Malware verdicts</span>    
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(sandboxStatistic?.palo_malware_checked_requests_today) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                        </div>
                        
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={forti} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(sandboxStatistic?.forti_malware_checked_requests_today) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>
                                Verdicts today / all</span> */}
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={kasper} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{(sandboxStatistic?.ktip_malware_checked_requests_today) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Checked hashes today / all</span> */}
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }<span style={{fontSize: 10}}>TV</span></div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{( sandboxStatistic?.tv_malware_checked_requests_today) || '0'}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                        </div>
                        {/* <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={kasper_sandbox} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(sandboxStatistic?.kasper_sb_malware_checked_requests_today) || '0'}</span>
                                </div> */}
                                <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={kasper_atap} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(sandboxStatistic?.kaspersky_atap_malware_checked_requests_today) || '0'}</span>
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={chkp} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(sandboxStatistic?.checkpoint_malware_checked_requests_today) || '0'}</span>
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={clam} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{(sandboxStatistic?.clamav_malware_checked_requests_today) || '0'}</span>
                                </div>
                        </div>
                        </div>  
                        <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                        <span style={{textAlign: "center", fontSize: 14}}>Malware files</span>    
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{((sandboxStatistic?.palo_malware_files_today   || '0')+ ' / ' + (sandboxStatistic?.palo_malware_files_total|| '0'))}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                        </div>
                        
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                            { <img style={{ width: 30, height: 30 }} src={forti} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{((sandboxStatistic?.forti_malware_files_today   || '0')+ ' / ' + (sandboxStatistic?.forti_malware_files_total|| '0'))}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>
                                Verdicts today / all</span> */}
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center" }} className="badge bg-light text-dark">
                            {<img style={{ width: 30, height: 30 }} src={kasper} alt='badge'></img> }
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'}}><span>{((sandboxStatistic?.ktip_malware_files_today  || '0')+ ' / ' + (sandboxStatistic?.ktip_malware_files_total || '0'))}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Checked hashes today / all</span> */}
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px', alignItems: "center"}} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={palo} alt='badge'></img> }<span style={{fontSize: 10}}>TV</span></div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{((sandboxStatistic?.tv_malware_files_today  || '0')+ ' / ' + (sandboxStatistic?.tv_malware_files_total|| '0'))}</span>
                                {/* <span style={{ fontSize: '10px', fontWeight: 400 }}>Verdicts today / all</span> */}
                                </div>
                        </div>
                        {/* <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={kasper_sandbox} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{((sandboxStatistic?.kasper_sb_malware_files_today  || '0')+ ' / ' + (sandboxStatistic?.kasper_sb_malware_files_total|| '0'))}</span>
                                </div>
                        </div> */}
                                                <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={kasper_atap} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{((sandboxStatistic?.kaspersky_atap_malware_files_today || '0')+ ' / ' + (sandboxStatistic?.kaspersky_atap_malware_files_total|| '0'))}</span>
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={chkp} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{((sandboxStatistic?.checkpoint_malware_files_today || '0')+ ' / ' + (sandboxStatistic?.checkpoint_malware_files_total|| '0'))}</span>
                                </div>
                        </div>
                        <div style={{ height: 50, padding: '5px 10px 3px', fontSize: '16px', display: 'flex', flexDirection: 'row', gap: '3px',alignItems: "center" }} className="badge bg-light text-dark">
                          <div style={{display: "flex", flexDirection: "column"}}>  { <img style={{ width: 30, height: 30 }} src={clam} alt='badge'></img> }</div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '110px'  }}><span>{((sandboxStatistic?.clamav_malware_files_today || '0')+ ' / ' + (sandboxStatistic?.clamav_malware_files_total|| '0'))}</span>
                                </div>
                        </div>
                        </div>                            
                        </div>
                        </Col>
            </Row>
            <Tabs
                    id="sand-tabs"
                    activeKey={key}
                    onSelect={(key) => setKey(key)}
                    className="mb-3"
                >
                    <Tab eventKey="firewall" title="Firewalls">
                    <Row>
                {!addInstance && <Col className="col-2 mt-2 mb-2">
                    <Button variant="outline-success" size="sm" style={{ width: '100%' }} onClick={(e) => { setAddInstance(true) }}>Добавить</Button>
                </Col>}
            </Row>
            {addInstance && <AddAssetForm setAddInstance={setAddInstance} />}
            {patchAsset &&  <PatchAssetForm item={currentItem} setAddInstance={setPatchAsset} />}
            <Row className='mt-3' style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                <Col className='col-12' style={appletStyleStats}>
                    {isPreloader && <Preloader leftCoord={true} />}

                    <AssetsList platforms={platforms} allOrgs={allOrgs}
                        searchText={searchText} 
                        setSearchText={setSearchText}
                        orgFilter={orgFilter}
                        ordering={ordering}
                        setOrdering={setOrdering}
                        setOrgFilter={setOrgFilter}
                        type={type} setType={setType}
                       activeFilter = {activeFilter} setActiveFilter={setActiveFilter}
                       registeredFilter={registeredFilter} setRegisteredFilter={setRegisteredFilter}
                     connectFilter={connectFilter} setConnectFilter={setConnectFilter}
                        switchActive={switchActive} setSwitchActive={setSwitchActive} setDeleteItem={setDeleteItem} setPatchAsset={setPatchAsset}
                        setCurrentItem={setCurrentItem} currentItem={currentItem}
                        errors={errors} page={page} setPage={setPage} assets={assets} handleClick={handleClick} paginationString={newPaginationString}

                    />
                </Col>
            </Row>
                        </Tab>
                        <Tab eventKey="sandbox" title="Sandboxes" >
                          {key==='sandbox' &&   <SandBoxList />}
                            </Tab>
                            <Tab eventKey="Requests" title="Requests" >
                          {key==='Requests' &&   <RequestsList  orgs={allOrgs} firewalls={assets}/>}
                            </Tab>
                            <Tab eventKey="Files" title="Files" >
                          {key==='Files' &&   <FilesList orgs={allOrgs}/>}
                            </Tab>
                            {/* <Tab eventKey="checkedFiles" title="Checked Files" >
                          {key==='checkedFiles' &&   <CheckedFilesList firewalls={assets}/>}
                            </Tab> */}
                            <Tab eventKey="licenses" title="Licenses" >
                          {key==='licenses' &&   <LicenseList />}
                            </Tab>
                            <Tab eventKey="signatures" title="PA пакеты" >
                          {key==='signatures' &&   <SignaturesList />}
                            </Tab>
                    </Tabs>
           

        </Col>
    )
}
export default SandboxArea;