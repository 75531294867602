import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import SandboxService from "../../API/SandboxService";
import Preloader from "../UI/Preloader/Preloader";
import CopyIcon from '../../icons/copy.svg';
import { changeDate, dateEndStatus } from "./utils";
import { Button, Form } from "react-bootstrap";
function SignaturesList() {
    const [currentItem, setCurrentItem] = useState(null);

    const [isPreloader, setIsPreloader] = useState(false);
    const [copytext, setCopyText] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [switchActive, setSwitchActive] = useState(false);
    const [addLicense, setAddLicense] = useState(false);
    const [patchSandbox, setPatchSandbox] = useState(false);
    const [signaturesData, setsignaturesData] = useState(null);
    const [dataCount, setDataCount] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [errors, setErrors] = useState([]);
    const [sandboxDataTable, setSandboxDataTable] = useState(null);
    const [viewDetail, setViewDetail] = useState(false);
    const [producerFilter, setProducerFilter] = useState(false);
    const [typeFilter, setTypeFilter] = useState(false)
    const [filteredArray, setFilteredArray] = useState(null);
    const [onlineFilter, setOnlineFilter] = useState(false);
    const [searchHash, setSearchHash] = useState('')
    const [searchSignature, setSearchSignature] = useState('')
   // const [ordering, setOrdering] = useState('-files_in_queue');
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const sandboxArray = filteredArray || sandboxDataTable || [];

    const fetchData = async (add) => {
        let filters = false;
        if (searchText || searchHash || searchSignature) {
            filters = {
                search: searchText,
                signatures__sha256: searchHash,
                signatures__signature_id:  searchSignature
            };
        }

        setIsPreloader(true);
        setIsAssetLoading(true);
        await SandboxService.getSignaturesList(filters, add ? nextPage : 1)
            .then((res) => {
                if (add === "add") {
                    res.items?.map((item)  =>
                        setsignaturesData((arr) => [...arr, item])
                    );
                    setNextPage(res.items?.length > 0 ? nextPage + 1 : null);
                } else {
                    setsignaturesData(res.items);
                    setNextPage(res.items?.length > 0 ? 2 : null);
                     setDataCount(res.total);
                
                // setNextPage(res.next ? nextPage + 1 : null);
                
            }
            setIsAssetLoading(false);
           
        })
            .catch(() => changeStatus())
            .finally(() => setIsPreloader(false));
    };
    useEffect(() => {
        if (
            !deleteItem &&
            !switchActive &&
            !addLicense &&
            !switchActive &&
            !patchSandbox
        ) {
            setIsPreloader(true);
            const timerRefresh = setTimeout(fetchData, 500);
            return () => {
                clearTimeout(timerRefresh);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchHash, searchSignature, searchText
    ]);


    useEffect(() => {
        (async () => {
            if (needToLoad && nextPage) {
                await fetchData("add");
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        if (isAssetLoading) return;
        if (observer.current) observer.current.disconnect();
        var callback = function (entries, observer) {
            if (
                entries[0].isIntersecting &&
                nextPage &&
                signaturesData?.length > 0
            ) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);
    }, [isAssetLoading]);
    const dateStyles = {
        fontSize: "x-small",
    };
    
const clearFilters = ()=> {
setSearchText('');
setSearchHash('');
setSearchSignature('')
}

const copyBufferText = (text, field) => {
    navigator.clipboard.writeText(text)
        .then((res) => {

            setCopyText({ text: 'Copy', field: field })
        })
        .catch(err => {
            setCopyText({ text: 'Error', field: field });
        });
}
    return (
        <>
            <Row
                style={{
                    margin: " 0 0 3px",
                    padding: "0 0 0 5px",
                    position: "relative",
                }}
                className="justify-content-between"
            >
                {isPreloader && <Preloader leftCoord={true} />}
                <Col className="col-3 mt-2 mb-2">
                    <b>Всего {dataCount} шт.</b>
                </Col>
            </Row>
            <Row className="mb-2" style={{ position: "relative" }}>
        <Col className="mb-3 col-3">
          <Form.Label size="sm">Поиск по пакету</Form.Label>
          <Form.Control
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Поиск"
            size="sm"
            style={{ fontSize: "1rem", height: 38 }}
            value={searchText}
          />
        </Col>
        <Col className="mb-3 col-3">
          <Form.Label size="sm">Поиск по сигнатурам</Form.Label>
          <Form.Control
            onChange={(e) => {
              setSearchSignature(e.target.value);
            }}
            placeholder="Поиск"
            size="sm"
            style={{ fontSize: "1rem", height: 38 }}
            value={searchSignature}
          />
        </Col>
        <Col className="mb-3 col-3">
          <Form.Label size="sm">Поиск по хеш</Form.Label>
          <Form.Control
            onChange={(e) => {
              setSearchHash(e.target.value);
            }}
            placeholder="Поиск"
            size="sm"
            style={{ fontSize: "1rem", height: 38 }}
            value={searchHash}
          />
        </Col>
        {(searchText ||
        searchHash || 
        searchSignature
          ) && (
          <Col className="col-2">
            <Button
              variant="outline-info"
              size="sm"
              style={{ width: "100%", height: "38px", margin: "32px 0 10px" }}
              onClick={() => clearFilters()}
            >
              Сбросить
            </Button>
          </Col>
        )}
        </Row>
            <hr style={{ margin: "5px 0" }} /> 
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}></Row>
            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}> 
                                
                                    <th>Номер пакета</th>
                                    <th style={{ width: "10%" }}>Дата{<br/>} Время (UTC)</th>
                                    <th style={{ width: "10%" }}>Размер {<br/>}(байты)</th>
                                    <th style={{ width: "10%" }}>Номера новых {<br/>}сигнатур</th>
                                    <th>Хеш</th>
                                </tr>
                            </thead>

                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {signaturesData?.map((item, index) => (
                                    <tr
                                        key={item.name + "sign" + item.created_at}
                                        style={dateStyles}
                                    >
                                        <td><div className={index===0 ? "badge bg-success": ''} style={{fontSize: 10}}>{item.name}</div></td>
                                        <td>{changeDate(item.created_at)}</td>
                                        <td>{item.size}</td>
                                        <td >{item.signatures?.map((it, ind) => <div key={it.signature_id + 'sign'} style={{width: '250px',textOverflow: "ellipsis", overflow:"hidden", whiteSpace:'nowrap', height: 20}}>{it?.signature_id}</div>)}</td>
                                        <td >{item.signatures?.map((it) => <div  style={{height: 20}}key={it.signature_id + 'sign' + it.sha256} >{it?.sha256}{' '}<img width="16" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(it.sha256, it.sha256);
                                    setCurrentItem({item: item, })

                                }
                            } />{' '}
                            {(it.name === currentItem?.name) && copytext ? <span style={copytext.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext.field === it.sha256 ? copytext.text : ''}</span> : <span></span>}
                          </div>)}</td>
                                       
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div ref={lastItem} style={{ height: 20 }} />
                </div>
            }
        </>
    );
}

export default SignaturesList;
