import React, {useState} from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ImgRemove from "../../icons/remove.png";
import ImgEdit from "../../icons/edit.png";
import { getManufactureNameWithIcon } from "./utils";
import CopyIcon from '../../icons/copy.svg';
import { Button, Dropdown, Form } from "react-bootstrap";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
function AssetsList({
    setCurrentItem,
    currentItem,
    assets,
    errors,
    paginationString,
    page,
    setPage,
    setSwitchActive,
    setDeleteItem,
    setPatchAsset,
    searchText,
    setSearchText,
    orgFilter,
    setOrgFilter,
    allOrgs,
    ordering,
    setOrdering,
    type, setType,
    activeFilter,setActiveFilter,
    registeredFilter,setRegisteredFilter,
  connectFilter, setConnectFilter
}) {
    const dateStyles = {
        fontSize: "10px",
    };

    const [copytext, setCopyText] = useState(false);
    const changeDate = (date) => {
        if (date) {
            const formatDate = date?.split("T");
            const reverseDate =
                formatDate[0].split("-").reverse().join(".") +
                " " +
                formatDate[1].split(".")[0];

            return reverseDate;
        } else return null;
    };
    const getActiveFlag = (device) => {
        if (device.active_flag !== 1)
            return <span>{device.firewall.serial_nr}</span>;
        else return <span>{device.firewall.serial_nr}</span>;
    };

 const changeDateAndCalculate=(date)=>{
const nowIso  = new Date().toISOString();
let color=''
const now  = new Date(nowIso)
const activityDate = new Date(date);
const calculate  = now.getTime() - activityDate.getTime();
if (calculate > 3600000) color = 'red'
else color = 'green'
return color
 }

 const copyBufferText = (text, field) => {
    navigator.clipboard.writeText(text)
        .then((res) => {

            setCopyText({ text: 'Copy', field: field })
        })
        .catch(err => {
            setCopyText({ text: 'Error', field: field });
        });
}
const clearFilters=()=> {
    setSearchText('')
    setOrgFilter(false);
    setType(false);
    setOrdering('-last_active')
    setActiveFilter(false)
    setConnectFilter(false);
    setRegisteredFilter(false);
}
    return (
        <>
            <Row>
                <Col className="col-3 mt-2 mb-2">
                    <b>Всего: {assets.length}шт.</b>
                </Col>
            </Row>

            <hr style={{ margin: "5px 0" }} />
            <Row className="mb-2" style={{ position: "relative" }}>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Поиск </Form.Label>
                    <Form.Control
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        placeholder="Поиск по SN"
                        size="sm"
                        style={{ fontSize: "1rem", height: 38 }}
                        value={searchText}
                    />
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Организация</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-orgs"   size={'min'} >
                            {orgFilter?.name|| "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setOrgFilter}
                            type='orgFilter'

                        >
                            {allOrgs?.map((item, index)=> 
                            <Dropdown.Item eventKey={item.name + 'or'} key={item.name + 'or'} onClick={() => { setOrgFilter(item) }}>{item.name}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Производитель</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle
                          size={'min'}
                            as={MyToggleAsset}
                            id="dropdown-verdict-source"
                        >
                            {type?.title || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setType}
                            type="verdictFilter"
                        >
                            <Dropdown.Item
                                eventKey={"source" + 1}
                                key={"source" + 1}
                                onClick={() => {
                                    setType({title: "Palo Alto Networks", key: 'palo_alto'});
                                }}
                            >
                                {"Palo Alto Networks"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"source" + 2}
                                key={"source" + 2}
                                onClick={() => {
                                    setType({title: 'Fortinet', key: 'fortinet'});
                                }}
                            >
                                {'Fortinet'}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"source" + 3}
                                key={"source" + 3}
                                onClick={() => {
                                    setType({title: 'Checkpoint', key: 'checkpoint'});
                                }}
                            >
                                {'Checkpoint'}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2">
          <Form.Label size="sm">Активные</Form.Label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckCheckedWithKfeed"
              checked={activeFilter}
              onChange={(e) => {
                setActiveFilter(e.target.checked);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            ></label>
          </div>
        </Col>
        <Col className="mb-3 col-2">
          <Form.Label size="sm">Зарегистрированные</Form.Label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckCheckedWithKfeed"
              checked={registeredFilter}
              onChange={(e) => {
                setRegisteredFilter(e.target.checked);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            ></label>
          </div>
        </Col>
        <Col className="mb-3 col-2">
          <Form.Label size="sm">Подключенные</Form.Label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckCheckedWithKfeed"
              checked={connectFilter}
              onChange={(e) => {
                setConnectFilter(e.target.checked);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            ></label>
          </div>
        </Col>
                {(searchText ||
                    orgFilter || 
                    type || activeFilter || connectFilter || registeredFilter || 
                    ordering !== "-last_active") && (
                    <Col className="col-2">
                        <Button
                            variant="outline-info"
                            size="sm"
                            style={{
                                width: "100%",
                                height: "38px",
                                margin: "32px 0 10px",
                            }}
                            onClick={() => clearFilters()}
                        >
                            Сбросить
                        </Button>
                    </Col>
                )}
            </Row>

            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}>
                                    <th>SN</th>
                                    <th>Org
                                    {
                      <span
                        style={{
                          color: ordering === "organisation_name" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "organisation_name"
                            ? setOrdering("organisation_name")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-organisation_name" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-organisation_name"
                            ? setOrdering("-organisation_name")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                                        
                                    </th>
                                    <th>License{<br/>}token</th>
                                    <th>Platform</th>
                                    <th>OS</th>
                                    {/* <th >Last Request{<br />} DateTime</th> */}
                                    {/* <th>
                                        Last {<br/>}FileCheck {<br />}Datetime {<br/>}(UTC)
                                    </th> */}
                                    <th>Files / {<br/>}Requests{<br/>}( today ) 
                                    {
                      <span
                        style={{
                          color: ordering === "requests_today" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "requests_today"
                            ? setOrdering("requests_today")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-requests_today" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-requests_today"
                            ? setOrdering("-requests_today")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }</th>
                                    <th>Suspicious{<br/>} files / {<br/>}requests{<br />} (today)
                                    {
                      <span
                        style={{
                          color: ordering === "malware_requests_today" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "malware_requests_today"
                            ? setOrdering("malware_requests_today")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-malware_requests_today" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-malware_requests_today"
                            ? setOrdering("-malware_requests_today")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }
                                        </th>
                                    <th>Comment</th>
                                    <th>IP </th>
                                    <th>Status / {<br/>}
                                   last active{<br/>} (UTC)
                                   {
                      <span
                        style={{
                          color: ordering === "last_active" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "last_active"
                            ? setOrdering("last_active")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-last_active" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-last_active"
                            ? setOrdering("-last_active")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }</th>
                                  
                                    <th style={{ width: "5%" }}>
                                        Activate/ {<br />} Deactivate
                                    </th>
                                    
                                    <th style={{ width: "5%" }}>Actions</th>
                                  
                                </tr>
                            </thead>

                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {
                                    assets?.slice(page * 20, page * 20 + 20)
                                    .map((item, index) => (
                                        <tr
                                            key={item.id + "assetsUC"}
                                            style={dateStyles}
                                        >
                                            <td>{getActiveFlag(item)}<div>
                                                {getManufactureNameWithIcon(item.type)}</div></td>

                                            <td>
                                                {item.firewall.organisation}
                                            </td>
                                            {/* <td>
                                                {" "}
                                                { (
                                                  <div style={{display: "flex", gap: 5, alignItems: "center" }}><div  style={{
                                                    width: "10px",
                                                    height: "9px",
                                                    borderRadius: "50%",
                                                    background: changeDateAndCalculate(item.last_active),
                                                }}></div> <span
                                                        style={{color: changeDateAndCalculate(item.last_active)}}
                                                    >
                                                        {changeDate(item.last_active)}
                                                    </span></div> 
                                                ) }
                                            </td> */}
                                            <td title={item.license}>{item.license ? <><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(item.license, 'access');
                                    setCurrentItem(item)

                                }
                            } />
                                {item.id === currentItem?.id && copytext ? <span style={copytext.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext.field === "access" ? copytext.text : ''}</span> : <span></span>}
                            </> : ''}
                            </td>
                                            <td>
                                                {item.firewall.platform_name}
                                            </td>

                                            <td>{item.firewall.os_version}</td>
                                            {/* <td>
                                                {changeDate(
                                                    item.last_request_check_at
                                                )}
                                            </td> */}
                                            <td>{item.files_today || '0'} / {item.requests_today || '0'}</td>
                                            <td>{item.malware_files_today || '0'} / {item.malware_requests_today || '0'}</td>
                                            <td>{item.comment}</td>
                                            <td>
                                                {item.device_ip?.map(
                                                    (it, index) => (
                                                        <div
                                                            key={
                                                                "ip" +
                                                                index +
                                                                it
                                                            }
                                                        >
                                                            {it}
                                                            <br />
                                                        </div>
                                                    )
                                                )}
                                            </td>
                                            <td>
                                                {" "}
                                                { (
                                                  <div style={{display: "flex", gap: 5, alignItems: "center" }}><div  style={{
                                                    width: "10px",
                                                    height: "9px",
                                                    borderRadius: "50%",
                                                    background: changeDateAndCalculate(item.last_active),
                                                }}></div> <span
                                                        style={{color: changeDateAndCalculate(item.last_active)}}
                                                    >
                                                        {changeDate(item.last_active)}
                                                    </span></div> 
                                                ) }
                                            </td>
                                            <td>
                                                {
                                                    <Col className="col-2">
                                                        <div
                                                            className="form-check form-switch"
                                                            style={{
                                                                minHeight: 0,
                                                            }}
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusChecks"
                                                                checked={
                                                                    item.is_active ===
                                                                    !true
                                                                        ? false
                                                                        : true
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setSwitchActive(
                                                                        true
                                                                    );
                                                                    setCurrentItem(
                                                                        item
                                                                    );
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="flexSwitchCheckChecked"
                                                            ></label>
                                                        </div>
                                                    </Col>
                                                }{" "}
                                            </td>
                                            <td>
                                                <img
                                                    width="18"
                                                    className="interactive_hover"
                                                    alt={"edit"}
                                                    src={ImgEdit}
                                                    disabled
                                                    onClick={() => {
                                                        setCurrentItem(item);
                                                        setPatchAsset(true);
                                                    }}
                                                />
                                        
                                           
                                                <img
                                                    width="20"
                                                    src={ImgRemove}
                                                    alt="delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDeleteItem(true);
                                                        setCurrentItem(item);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <nav aria-label="pagination" style={{ marginTop: "auto" }}>
                        <ul
                            className="pagination justify-content-center"
                            id="pagination-menu"
                            style={{ margin: "0, auto" }}
                        >
                            {assets && (
                                <li className="page-item">
                                    <button
                                        disabled={page === 0 ? true : false}
                                        className="page-link"
                                        aria-label="Previous"
                                        onClick={() => setPage(page - 1)}
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                    </button>{" "}
                                </li>
                            )}
                            {paginationString(assets?.length)}
                            {assets && (
                                <li className="page-item">
                                    <button
                                        disabled={
                                            page + 1 > assets.length / 20
                                                ? true
                                                : false
                                        }
                                        className="page-link"
                                        aria-label="Next"
                                        onClick={() => setPage(page + 1)}
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                    </button>{" "}
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            }
        </>
    );
}

export default AssetsList;
