import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ImgRemove from "../../icons/remove.png";
import AddSandboxForm from "./AddSandboxForm";
import Auth from "../../API/Auth";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import { AuthContext } from "../../context";
import Alert from "react-bootstrap/Alert";
import SandboxService from "../../API/SandboxService";
import Button from "react-bootstrap/esm/Button";
import Preloader from "../UI/Preloader/Preloader";
import PatchSandboxForm from "./PatchSandboxForm";
import ImgEdit from "../../icons/edit.png";
import MyModal from "../UI/MyModal/MyModal";
import help from "../../icons/help.svg";
import SandboxDetailCard from "./SandboxDetailCard";
import forti from '../../icons/fortinet.svg'
import palo from '../../icons/PANW.svg'
import clam from '../../icons/Clam.png'
import chkp from '../../icons/CHKP.svg'
import kasper from '../../icons/kasper.svg'
import kasper_sandbox from '../../icons/product_logo.png'
import kasper_atap from '../../icons/rs-b-kata.png'
import { manualFilter, sortFileTypeS } from "./utils";
import { Dropdown, Form } from "react-bootstrap";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
function SandBoxList({}) {
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(false);
    const [switchActive, setSwitchActive] = useState(false);
    const [addSandbox, setAddSandbox] = useState(false);
    const [patchSandbox, setPatchSandbox] = useState(false);
    const [sandboxData, setSandboxData] = useState(null);
    const [dataCount, setDataCount] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [activeFilter, setActiveFilter] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [errors, setErrors] = useState([]);
    const [sandboxBalancerData, setSandboxBalancerData] = useState(null);
    const [sandboxBalancerDataQueue, setSandboxBalancerDataQueue] = useState(null);
    const [sandboxDataTable, setSandboxDataTable] = useState(null);
    const [renew, setRenew] = useState(false);
    const [viewDetail, setViewDetail] = useState(false);
    const [producerFilter, setProducerFilter] = useState(false);
    const [typeFilter, setTypeFilter] = useState(false)
    const [filteredArray, setFilteredArray] = useState(null);
    const [onlineFilter, setOnlineFilter] = useState(false);
    const [ordering, setOrdering] = useState('-files_in_queue');
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const sandboxArray = filteredArray || sandboxDataTable || [];

    const fetchData = async (add) => {
        let filters = false;
        if (searchText || activeFilter) {
            filters = {
                search: searchText,
                is_active: activeFilter,
            };
        }

        setIsPreloader(true);
        setIsAssetLoading(true);
        await SandboxService.getSandboxesList(filters, add ? nextPage : 1)
            .then((res) => {
                if (add === "add") {
                    res.results?.map((item) =>
                        setSandboxData((arr) => [...arr, item])
                    );
                    setNextPage(res.next ? nextPage + 1 : null);
                } else {
                    if (filteredArray) {
                    getBalancerInfo();}
                    setSandboxData(res.results);
                    setNextPage(res.next ? 2 : null);
                    setDataCount(res.count);
                
                // setNextPage(res.next ? nextPage + 1 : null);
              
            }  setIsAssetLoading(false);})
            .catch(() => changeStatus())
            .finally(() => setIsPreloader(false));
    };
    useEffect(() => {
        if (
            !deleteItem &&
            !addSandbox &&
            !switchActive &&
            !patchSandbox
        ) {
            setIsPreloader(true);
            const timerRefresh = setTimeout(fetchData, 1000);
            return () => {
                clearTimeout(timerRefresh);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        activeFilter,
        searchText,
        deleteItem,
        addSandbox,
        switchActive,
        patchSandbox,
        renew
    ]);
    useEffect(() => {
        if (!addSandbox && !patchSandbox && !switchActive){
        (async () => {
            setIsPreloader(true)
            await SandboxService.getSandboxesBalancer().then((resp) =>
               { setSandboxBalancerData(resp); 
          
              }
            ).catch((err)=> setErrors(err.message)).finally(()=> setIsPreloader(false))
            
            await SandboxService.getSandboxesBalancerQueue().then((resp) =>
            setSandboxBalancerDataQueue(resp)).catch((err)=> setErrors(err.message)).finally(()=> setIsPreloader(false))
        
        })();}
    }, [renew, switchActive, patchSandbox, addSandbox]);
    const getBalancerInfo=()=> {
        let arrFilesInQ = sandboxData?.map((item) => {
            return {
                ...item,
                files_in_queue: sandboxBalancerData.balancer_usage?.filter(
                    (it) =>{ return item.external_ip === it.wf500_addr}
                ),
            };
        });
        let arrFileProc = arrFilesInQ?.map((item) => {
            return {
                ...item,
                files_processed: sandboxBalancerData.files_processed?.filter(
                    (it) => item.external_ip === it.wf500_addr
                ),
            };
        });
        let arrSandboxStatus = arrFileProc?.map((item) => {
            return {
                ...item,
                status: sandboxBalancerData.health_status?.filter(
                    (it) => item.external_ip === it.wf500_addr
                ),
            };
        });
        let arrSandboxUnsupported = arrSandboxStatus?.map((item) => {
            return {
                ...item,
                unsupported: sandboxBalancerData.unsupported_files?.filter(
                    (it) => item.external_ip === it.wf500_addr
                ),
            };
        });
        let arrResult = arrSandboxUnsupported?.map((item, index) => {
            return {
                ...item,
                files_in_queue:
                    item.files_in_queue?.length > 0
                        ? item.files_in_queue[0].files_in_queue
                        : false,
                files_processed:
                    item.files_processed?.length > 0
                        ? item.files_processed[0].files_processed
                        : false,
                        unsupported:
                        item.unsupported?.length > 0
                            ? item.unsupported[0]
                            : false,
                status: item.status?.length > 0 ? item.status[0] : false,

            };
        });
        // let arrFinal = arrResult?.map((item)=> {
        //     return {...item, supported_file_types: sortFileTypeS(item.supported_file_types?.split(',')).join(",")}
        // })
        setSandboxDataTable(arrResult);
        manualFilter(arrResult, producerFilter?.key, typeFilter, onlineFilter,ordering, setFilteredArray)
    }
    
    useEffect(() => {
        if (sandboxData && sandboxBalancerData) {
            getBalancerInfo()
    }}, [sandboxData, sandboxBalancerData]);

    const getStatus = (status, detail, type, active) => {

        let value = "";
        if (type!=='kasp') {
        if (status === 200 && active)
            value = (
                <div
                    style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background: "lime",
                    }}
                ></div>
            );
        else if (status === "dead")
            value = (
                <div
                    style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        color: "red",
                    }}
                >
                    <div
                        style={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            background: "red",
                        }}
                    ></div>
                    <span>dead</span>{" "}
                    {detail ? (
                        <img
                            title={detail}
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            src={help}
                            alt="tooltip"
                        ></img>
                    ) : (
                        ""
                    )}
                </div>
            );
        else
            value = (
                <div
                    style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        color: "red",
                    }}
                >
                    <div
                        style={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            background: "red",
                        }}
                    ></div>
                    <span>{status}</span>{" "}
                    {detail ? (
                        <img
                            title={detail}
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            src={help}
                            alt="tooltip"
                        ></img>
                    ) : (
                        ""
                    )}
                </div>
            );} else{ if (active) value = (
                <div
                    style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background: "lime",
                    }}
                ></div>
            );else value= '-'}
        return value;
    };
    useEffect(() => {
        (async () => {
            if (needToLoad && nextPage) {
                await fetchData("add");
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        if (isAssetLoading) return;
        if (observer.current) observer.current.disconnect();
        var callback = function (entries, observer) {
            if (
                entries[0].isIntersecting &&
                nextPage &&
                sandboxData?.length > 0
            ) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);
    }, [isAssetLoading]);
    const dateStyles = {
        fontSize: "x-small",
    };
    // const tdStyle = {
    //     padding: '4px'
    // }

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                if (type === "delete") deleteAsset(currentItem.id);
            })
            .catch(() => changeStatus());
    };
    const deleteAsset = async (id) => {
        setIsPreloader(true);
        await SandboxService.deleteSandbox(id)
            .then(() => {
                setIsPreloader(false);
                setDeleteItem(false);
            })
            .catch((e) => {
                if (e.message[0].includes("token")) refreshToken("delete");
                else {
                    setIsPreloader(false);
                    setErrors(e.message);
                }
            });
    };
    const switchActivationAsset = async (item) => {
        const formData = new FormData();

        setIsPreloader(true);
        formData.append("is_active", !item.is_active);
        await SandboxService.switchActivateSandbox(item.id, formData)
            .then(() => {
                setIsPreloader(false);
                setSwitchActive(false);
            })
            .catch((e) => {
                if (e.message[0].includes("token")) refreshToken("put");
                else {
                    setIsPreloader(false);
                    setErrors(e.message);
                }
            });
    };
const clearFilters = ()=> {
    setProducerFilter(false)
    setTypeFilter(false)
    setFilteredArray(null)
    setOnlineFilter(false)
    setOrdering('-files_in_queue')
}
useEffect(()=> {
if (sandboxDataTable) manualFilter(sandboxDataTable, producerFilter, typeFilter, onlineFilter,ordering, setFilteredArray);
},[ordering])

const getManufactureImage=(type)=> {
    let image= ''
    
    switch(type){
        case('palo_alto'): image= palo; break;
        case('forti'): image=forti;break;
        case('kaspersky'): image=kasper; break;
        case('kaspersky_atap'): image=kasper_atap; break;
        case('checkpoint'): image=chkp; break;
        case('clamav'): image=clam; break;
        default: image = kasper_sandbox

    }
    return image;
} 
    return (
        <>
          {viewDetail && <MyModal visible={viewDetail} setVisible={setViewDetail}>
                <div style={{ width: '50vw', margin: '0 auto', maxHeight: '80vh', overflowY: 'scroll' }}>
                    <Row style={{ margin: 0 }}>

                        <h5 className="mb-3">Queue detail {currentItem.external_ip}</h5>
                    </Row>

                    <Row style={{ margin: 0 }}>
                        {<SandboxDetailCard data={sandboxBalancerDataQueue}
                            serial={currentItem.external_ip}
                        />}
                    </Row>

                </div>
            </MyModal>}
            {" "}
            {patchSandbox && (
                // <MyModal visible={patchSandbox} setVisible={setPatchSandbox}>
                    <PatchSandboxForm
                        item={currentItem}
                        setAddInstance={setPatchSandbox}
                    />
                // </MyModal>
            )}
            {switchActive && (
                <MyConfirm visible={switchActive} setVisible={setSwitchActive}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}
                        <h3 className="mb-4 mt-4">
                            Вы точно хотите{" "}
                            {currentItem.is_active === false
                                ? "активировать"
                                : "деактивировать"}{" "}
                            {<br />}устройство {currentItem.serial}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errors.length > 0 &&
                            errors.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    switchActivationAsset(currentItem);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSwitchActive(false);
                                    setErrors([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            {deleteItem && (
                <MyConfirm visible={deleteItem} setVisible={setDeleteItem}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}
                        <h3 className="mb-4 mt-4">
                            Вы точно хотите удалить доступ{<br />}{" "}
                            {currentItem.name}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errors?.length > 0 &&
                            errors?.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteAsset(currentItem.id);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteItem(false);
                                    setErrors([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            <Row className="justify-content-between mt-3 mb-4">
                {!addSandbox && (
                    <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="outline-success"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setAddSandbox(true);
                            }}
                        >
                            Добавить
                        </Button>
                    </Col>
                )}
                {!addSandbox && (
                    <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="primary"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setRenew(!renew);
                            }}
                        >
                            Обновить
                        </Button>
                    </Col>
                )}
            </Row>
            {addSandbox && <AddSandboxForm setAddInstance={setAddSandbox} />}
            <Row
                style={{
                    margin: " 0 0 3px",
                    padding: "0 0 0 5px",
                    position: "relative",
                }}
                className="justify-content-between"
            >
                {isPreloader && <Preloader leftCoord={true} />}
                <Col className="col-3 mt-2 mb-2">
                    <b>Всего {dataCount} шт.</b>
                </Col>
            </Row>
            <Row>
            <Col className="mb-3 col-3">
          <Form.Label size="sm">Производитель</Form.Label>
          <Dropdown>
            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-type">
              {producerFilter.title || "выбрать"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={MyMenu}
              filterAssets={setProducerFilter}
              type="typeFilter"
            >
              <Dropdown.Item
                eventKey={"t" + 1}
                key={"t" + 1}
                onClick={() => {
                    setProducerFilter({title: 'Palo Alto Network', key:"palo_alto" })
                  manualFilter(sandboxDataTable, "palo_alto", typeFilter, onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"Palo Alto Networks"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 2}
                key={"t" + 2}
                onClick={() => {
                  setProducerFilter({title: "Fortinet", key:"forti" });
                  manualFilter(sandboxDataTable, "forti", typeFilter,onlineFilter, ordering, setFilteredArray);
                }}
              >
                {"Fortinet"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 3}
                key={"t" + 3}
                onClick={() => {
                  setProducerFilter({title: "Kaspersky Threat Lookup", key: "kaspersky"});
                  manualFilter(sandboxDataTable, "kaspersky", typeFilter, onlineFilter, ordering,setFilteredArray);
                }}
              >
                {"Kaspersky Threat Lookup"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 4}
                key={"t" + 4}
                onClick={() => {
                  setProducerFilter({title: "Kaspersky ATAP", key: "kaspersky_atap"});
                  manualFilter(sandboxDataTable, "kaspersky_atap",typeFilter, onlineFilter, ordering,setFilteredArray);
                }}
              >
                {"Kaspersky ATAP"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 5}
                key={"t" + 5}
                onClick={() => {
                  setProducerFilter({title: "Checkpoint", key: "checkpoint"});
                  manualFilter(sandboxDataTable, "checkpoint",typeFilter, onlineFilter, ordering,setFilteredArray);
                }}
              >
                {"Checkpoint"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 6}
                key={"t" + 6}
                onClick={() => {
                  setProducerFilter({title: "ClamAV", key: "clamav"});
                  manualFilter(sandboxDataTable, "clamav",typeFilter, onlineFilter, ordering,setFilteredArray);
                }}
              >
                {"ClamAV"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="mb-3 col-3">
          <Form.Label size="sm">Тип файлов</Form.Label>
          <Dropdown>
            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-type">
              {typeFilter || "выбрать"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={MyMenu}
              filterAssets={setTypeFilter}
              type="typeFilter"
            >
              <Dropdown.Item
                eventKey={"t" + 1}
                key={"f" + 1}
                onClick={() => {
                    setTypeFilter('apk')
                  manualFilter(sandboxDataTable, producerFilter?.key, 'apk', onlineFilter, ordering,setFilteredArray);
                }}
              >
                {"apk"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 2}
                key={"f" + 2}
                onClick={() => {
                    setTypeFilter("archive");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'archive', onlineFilter, ordering, setFilteredArray);
                }}
              >
                {"archive"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 3}
                key={"f" + 3}
                onClick={() => {
                    setTypeFilter("flash");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'flash', onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"flash"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 4}
                key={"t" + 4}
                onClick={() => {
                    setTypeFilter("jar");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'jar', onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"jar"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 5}
                key={"t" + 5}
                onClick={() => {
                    setTypeFilter("linux");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'linux', onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"linux"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 6}
                key={"t" + 6}
                onClick={() => {
                    setTypeFilter("ms-office");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'ms-office', onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"ms-office"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 7}
                key={"t" + 7}
                onClick={() => {
                    setTypeFilter("pdf");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'pdf', onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"pdf"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 8}
                key={"t" + 8}
                onClick={() => {
                    setTypeFilter("pe");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'pe',onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"pe"}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"t" + 9}
                key={"t" + 9}
                onClick={() => {
                    setTypeFilter("script");
                  manualFilter(sandboxDataTable, producerFilter?.key, 'script', onlineFilter,ordering, setFilteredArray);
                }}
              >
                {"script"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
     
        <Col className="mb-3 col-2">
          <Form.Label size="sm">Статус (онлайн)</Form.Label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckCheckedWithKfeed"
              checked={onlineFilter}
              onChange={(e) => {

                setOnlineFilter(e.target.checked);  
                manualFilter(sandboxDataTable, producerFilter?.key, typeFilter, e.target.checked, ordering, setFilteredArray)
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            ></label>
          </div>
        </Col>
        {(producerFilter || typeFilter || onlineFilter) &&  <Col className="col-2">
       <Button variant="outline-info"
              size="sm"
              style={{ width: "100%", height: "38px", margin: "32px 0 10px" }}
              onClick={() => clearFilters()}type='primary'>Сбросить</Button>
       </Col>}
            </Row>
            <hr style={{ margin: "5px 0" }} />
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}></Row>
            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}>
                                    <th>Имя {<span
                        style={{
                          color: ordering === "name" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "name"
                            ? setOrdering("name")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-name" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-name"
                            ? setOrdering("-name")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>}</th>
                                    <th>Внешний IP</th>
                                    <th>Внутренний IP</th>
                                    <th>Status</th>
                                    <th>Queue  {
                      <span
                        style={{
                          color: ordering === "files_in_queue" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "files_in_queue"
                            ? setOrdering("files_in_queue")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-files_in_queue" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-files_in_queue"
                            ? setOrdering("-files_in_queue")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>
                    }</th>
                                    <th>Files processed</th>
                                    <th>Поддерживаемые{<br/>}типы {<span
                        style={{
                          color: ordering === "supported_file_types" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "supported_file_types"
                            ? setOrdering("supported_file_types")
                            : setOrdering(false)
                        }
                      >
                        ↑
                      </span>
                    }
                    {
                      <span
                        style={{
                          color: ordering === "-supported_file_types" ? "blue" : "black",
                          marginLeft: "5px",
                          fontWeight: 900,
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          ordering !== "-supported_file_types"
                            ? setOrdering("-supported_file_types")
                            : setOrdering(false)
                        }
                      >
                        ↓
                      </span>}</th>
                                    {/* <th>Unsupported<br/>today / total</th> */}
                                    <th>Описание</th>
                                    <th style={{ width: "10%" }}>
                                        Activate/ {<br />} Deactivate
                                    </th>
                                    <th style={{ width: "5%" }}>Изменить</th>
                                    <th style={{ width: "5%" }}>Удалить</th>
                                </tr>
                            </thead>

                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {sandboxArray.map((item, index) => (
                                    <tr
                                        key={item.id + "sandUC"}
                                        style={dateStyles}
                                    >
                                        <td ><div style={{display: "flex", alignItems: "center", gap: 6}}> <img
                                        style={{background: 'transparent'}}
                                                    width="16"
                                                    className="interactive_hover"
                                                    alt={"edit"}
                                                    src={getManufactureImage(item.type)}
                                                    disabled
                                                   
                                                />{item.name}</div></td>
                                        <td>{item.external_ip}</td>

                                        <td>{item.internal_ip}</td>
                                        {/* <td>
                                           {(item.type!=='kaspersky' &&  getStatus(
                                                item.status.status,
                                                item.status.detail,
                                                (item.type==='kaspersky_sandbox'|| item.type==='kaspersky_atap')? 'kasp' : null,
                                                (item.type==='kaspersky_sandbox'|| item.type==='kaspersky_atap')? item.is_active: false
                                           )) || "-"}
                                        </td> */}
                                        <td>
                                           {(getStatus(
                                                item.status.status,
                                                item.status.detail,
                                              null,
                                               item.is_active
                                           )) || "-"}
                                        </td>
                                        <td>{item.files_in_queue==='0'?  item.files_in_queue
                                        : item.files_in_queue !== false && <div style={{display: "flex", gap: 5, alignItems: "center"}}><span style={{color: 'blue', cursor: 'pointer'}}>{item.files_in_queue}</span><Button onClick={()=> {setCurrentItem(item); setViewDetail(true)}}type="primary" style={{width: 30, height: 15, padding:0,  fontSize: 8}}>Detail</Button></div>}</td>
                                        <td>{item.files_processed || "-"}</td>
                                        <td>{sortFileTypeS(item.supported_file_types?.split(','), ordering==='-supported_file_types')?.map((type, ind)=> <span key={type}>{type}{ind===item.supported_file_types?.split(',')?.length-1?'': ', '}</span>) || "-"}</td>
                                        {/* <td>{item.unsupported ?  item.unsupported.unsupported_today + ' / ' + item.unsupported.unsupported_total :  "-"}</td> */}
                                        <td>{item.description}</td>
                                        <td>
                                            {
                                                <Col className="col-2">
                                                    <div
                                                        className="form-check form-switch"
                                                        style={{ minHeight: 0 }}
                                                    >
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusChecks"
                                                            checked={
                                                                item.is_active ===
                                                                !true
                                                                    ? false
                                                                    : true
                                                            }
                                                            onChange={(e) => {
                                                                setSwitchActive(
                                                                    true
                                                                );
                                                                setCurrentItem(
                                                                    item
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexSwitchCheckChecked"
                                                        ></label>
                                                    </div>
                                                </Col>
                                            }{" "}
                                        </td>
                                        <td>
                                            <img
                                                width="20"
                                                className="interactive_hover"
                                                alt={"edit"}
                                                src={ImgEdit}
                                                disabled
                                                onClick={() => {
                                                    setCurrentItem(item);
                                                    setPatchSandbox(true);
                                                }}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                cursor: "pointer",
                                                padding: "4px",
                                            }}
                                        >
                                            <img
                                                width="20"
                                                src={ImgRemove}
                                                alt="delete"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteItem(true);
                                                    setCurrentItem(item);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div ref={lastItem} style={{ height: 20 }} />
                </div>
            }
        </>
    );
}

export default SandBoxList;
