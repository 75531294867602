import Api from '.';

const getStatusQueryParams= (status)=> {
    let value = null
    switch (status) {
        case('Accepted'): value= 'accepted'; break;
        case('Processing'): value= 'stage_01,stage_02,stage_03,'; break;
        case('Finished'): value = 'succeeded,finished'; break;
        case('Stage 1'): value = 'stage_01'; break;
        case('Stage 2'): value = 'stage_02'; break;
        case('Stage 3'): value = 'stage_03'; break;
        case('Error / Timeout'): value = 'timeout,error,failed'; break;
    }
    return value
}
export default class SandboxService {

    static async getAssetsList(org_id, filters) {
        var url = `api/v1/sandbox/firewall-access/`;
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.organisation) {
                params.organisation = filters.organisation;

            };
            if (filters.search) {
                params.search = filters.search;
            };
            if (filters.type__in) {
                params.type__in= filters.type__in;
            };
            if (filters.ordering) {
                params.order_by= filters.ordering;
            };
            if (filters.last_active__gte) {
                params.last_active__gte= filters.last_active__gte;
            };
            if (filters.is_active) {
                params.is_active= filters.is_active;
            };
            if (filters.is_registered) {
                params.is_registered= filters.is_registered;
            };
            if(filters.ordering) params.order_by = filters.ordering
            else params.order_by = '-last_active'
        }
        if (org_id) params.organisation = org_id;
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async switchActivate(id, active) {
        var url = `api/v1/sandbox/firewall-access/${id}/`;
        const method = 'PATCH';
        const data = {};
        data.is_active = active
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };

    static async deleteSerial(id) {
        var url = `api/v1/sandbox/firewall-access/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    };

    static async checkAsets(serial) {
        var url = `api/v1/assets/?serial_nr=${serial}`;
        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async addInstance(asset) {
        var url = `api/v1/sandbox/firewall-access/`;
        const method = 'POST';
        const data = asset;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async patchAsset(id, params) {
        var url = `api/v1/sandbox/firewall-access/${id}/`;
        const method = 'PATCH';
        const data = params;
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };

    //Sandbox

    static async getSandboxesList(nextpage, filters) {
        var url = `api/v1/sandbox/sandboxes/`;
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.is_active) {
                params.is_active = filters.is_active;

            };
            if (filters.search) {
                params.search = filters.search;
            };}
            if (nextpage) {
                params.page = nextpage;}
            params.limit = 50
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async addSandbox(sandbox) {
        var url = `api/v1/sandbox/sandboxes/`;
        const method = 'POST';
        const data = sandbox;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async deleteSandbox(id) {
        var url = `api/v1/sandbox/sandboxes/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    };
    static async switchActivateSandbox(id, data) {
        var url = `api/v1/sandbox/sandboxes/${id}/`;
        const method = 'PATCH';
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };
    static async patchSandbox(id, params) {
        var url = `api/v1/sandbox/sandboxes/${id}/`;
        const method = 'PATCH';
        const data = params;
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };
    static async getSandboxesBalancer() {
        var url = `api/v1/sandbox/statistics/sandbox-balancer/`;
        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getSandboxesBalancerQueue() {
        var url = `api/v1/sandbox/statistics/sandbox-balancer/files-in-queue/`;
        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getSandboxesCredentials(externalIp) {
        var url = `api/v1/sandbox/sandboxes/${externalIp}/credentials/`;
        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
//CHECKED FILES
static async getCheckedFilesList(filters, nextpage, malware ) {
    var url =!malware ? `api/v1/sandbox/statistics/checked-files/` : `api/v1/sandbox/statistics/checked-files-malware/`;
  
    const method = 'GET';
    const params = {};
    if (filters) {
        if (filters.verdict) {
            switch (filters.verdict) {
                case('Benign'): params.verdict__in = 0; break;
                case('Malware'): params.verdict__in = 1; break;
                case('Grayware'): params.verdict__in = 2; break;
                case('Phishing'): params.verdict__in = 4; break;
                case('Error'): params.verdict__in = 500; break;
                case('Unsupported'): params.verdict__in = 99; break;

            }

        };
        if (filters.verdict_fortisandbox__in) {
           params.verdict_fortisandbox__in = filters.verdict_fortisandbox__in

        };
        if (filters.verdict_ktip_hash_lookup) {
            params.verdict_ktip_hash_lookup = filters.verdict_ktip_hash_lookup
 
         };
         if (filters.verdict_threat_vault__in) {
            params.verdict_threat_vault__in = filters.verdict_threat_vault__in
 
         };
        if (filters.file_type) {
            params.file_type__in = filters.file_type;

        };
        if (filters.search) {
            params.search = filters.search;
        };
        if (filters.firewall_id__in) {
            params.firewall_id__in = filters.firewall_id__in;
        };}
        if (nextpage) {
            params.page = nextpage;}
    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};
//FILES
static async getFilesList(filters, nextpage) {
    var url =`api/v1/sandbox/statistics/files/`
  
    const method = 'GET';
    const params = {};
    if (filters) {
        if (filters.verdict) {
            switch (filters.verdict) {
                case('Benign'): params.verdict_palo_alto__in= 0; break;
                case('Malware'): params.verdict_palo_alto__in = 1; break;
                case('Grayware'): params.verdict_palo_alto__in = 2; break;
                case('Phishing'): params.verdict_palo_alto__in = 4; break;
                case('Error'): params.verdict_palo_alto__in = 500; break;
                case('Unsupported'): params.verdict_palo_alto__in = 99; break;

            }

        };
        if (filters.malware_only) params.malware_only = true
        if (filters.verdict_fortisandbox__in) {
           params.verdict_fortisandbox__in = filters.verdict_fortisandbox__in

        };
        if (filters.verdict_ktip_hash_lookup) {
            params.verdict_ktip_hash_lookup__in = filters.verdict_ktip_hash_lookup
 
         };
         if (filters.verdict_threat_vault__in) {
            params.verdict_threat_vault__in = filters.verdict_threat_vault__in
 
         };
        if (filters.file_type) {
            params.file_type__in = filters.file_type;
        };
        if (filters.pa_signatures) 
        params.pa_signatures = filters?.pa_signatures

        if (filters?.grayware_only)
        params.grayware_only = filters.grayware_only
        if (filters.false_positive) {
            params.false_positive = filters.false_positive
        }
        if (filters.search) {
            params.search = filters.search;
        };
        if (filters.firewall_id__in) {
            params.firewall_id__in = filters.firewall_id__in;
        };
        if (filters.verdict_checkpoint__in) {
            params.verdict_checkpoint__in = filters.verdict_checkpoint__in;
        }; 
        if (filters.verdict_kaspersky_atap__in) {
            params.verdict_kaspersky_atap__in = filters.verdict_kaspersky_atap__in;
        };  
        if (filters.verdict_clamav__in) {
            params.verdict_clamav__in = filters.verdict_clamav__in;
        }; 
    }
        if (nextpage) {
            params.page = nextpage;}
        if(filters.ordering) params.order_by = filters.ordering
        else params.order_by = '-last_seen'
    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};

//REQUESTS
static async getRequestsList(filters, nextpage, ) {
    var url = `api/v1/sandbox/statistics/requests/`
  
    const method = 'GET';
    const params = {};
    if (filters) {
        if (filters.verdict.length>0) {
            params.verdict__in = filters.verdict.map((item) =>   item).join(',')
            
        };
        if (filters.verdict_source__in) {
          params.verdict_source__in = filters.verdict_source__in

        };
        if (filters.status__in?.length> 0) {
             let arr= filters.status__in.map((item) =>   getStatusQueryParams(item)).join(',').split(',')
            //params.status__in = filters.status__in
            let uniqueArray = [...new Set(arr)]
            params.status__in = uniqueArray.join(',')
          };
        if (filters.file_type) {
            params.file_type__in = filters.file_type;

        };
        if (filters.false_positive) {
            params.false_positive = filters.false_positive
        }
        if (filters.source__in) {
            params.source__in = filters.source__in
        }
        if (filters.organisation_id) {
            params.source_org = filters.organisation_id;

        };
        if (filters.search) {
            params.search = filters.search;
        };
        if (filters.firewall_id__in) {
            params.firewall_id__in = filters.firewall_id__in;
        };}
        if (nextpage) {
            params.page = nextpage;}
        if(filters.ordering) params.order_by = filters.ordering
        else params.order_by = '-created_at'
    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};
static async getExportFileSandbox(hash, type, producer) {
    var url = producer==='palo'? `api/v1/sandbox/statistics/reports/${type}/${hash}/` : `api/v1/sandbox/statistics/reports/forti/${hash}/`
    const method = 'GET';
    const params = {};
    const api = new Api({ url: url, params: params, responseType: type==='pdf'? "blob" : 'text/xml' });
    const response = await api.request(method);
    return response;
};
static async getExportSampleFileSandbox(hash) {
    var url = `api/v1/sandbox/statistics/samples/${hash}/` 
    const method = 'GET';
    const params = {};
    const api = new Api({ url: url, params: params, responseType: "blob" });
    const response = await api.request(method);
    return response;
};
static async getSandboxVerdictDetail(hash) {
    var url = `api/v1/sandbox/statistics/checked-files/reports-forti/${hash}/`
    const method = 'GET';
    const params = {};
    const api = new Api({ url: url, params: params});
    const response = await api.request(method);
    return response.data;
}
    static async getRequestStatsDetail(sha256) {
        var url = `api/v1/sandbox/statistics/files/${sha256}/details/`
        const method = 'GET';
        const params = {};
        const api = new Api({ url: url, params: params});
        const response = await api.request(method);
        return response.data;
    };
    static async switchFalsePositive(sha, value) {
        var url = `api/v1/sandbox/statistics/files/${sha}/`;
        const method = 'PATCH';
        const data ={}
        data.false_positive = value;
        const api = new Api({ url: url});
        const response = await api.request(method, data);
        return response.data;
    };

//STATISTIC /api/v1/sandbox/statistics/basic-info
static async getSandboxStatistic(repeat) {
    var url = 'api/v1/sandbox/statistics/basic-info/'
    const method = 'GET';
    const params = {};
    if(repeat) params.force = true;
    const api = new Api({ url: url, params: params});
    const response = await api.request(method);
    return response.data;
};

//LICENSES
static async getLicensesList(filters, nextpage, ) {
    var url = `api/v1/sandbox/licenses/`
  
    const method = 'GET';
    const params = {};
    if (filters) {
        
        if (filters.organisation) {
            params.organisation = filters.organisation;

        };
        if (filters.search) {
            params.search = filters.search;
        };
        if (filters.firewall_id__in) {
            params.firewall_id__in = filters.firewall_id__in;
        };}
        if (nextpage) {
            params.page = nextpage;}
        // if(filters.ordering) params.order_by = filters.ordering
        // else params.order_by = '-created_at'
    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};
static async addLicense(license) {
    var url = `api/v1/sandbox/licenses/`;
    const method = 'POST';
    const data = license;
    const api = new Api({ url: url });
    const response = await api.request(method, data);
    return response.data;
};
static async getLicenseDetail(id) {
    var url = `api/v1/sandbox/licenses/${id}/`;
    const method = 'GET';
    const api = new Api({ url: url });
    const response = await api.request(method);
    return response.data;
};
static async deleteLicense(id) {
    var url = `api/v1/sandbox/licenses/${id}/`;
    const method = 'DELETE';
    const api = new Api({ url: url});
    const response = await api.request(method);
    return response.data;
};
static async patchLicense(id, license) {
    var url = `api/v1/sandbox/licenses/${id}/`;
    const method = 'PATCH';
    const data = license;
    const api = new Api({ url: url });
    const response = await api.request(method, data);
    return response.data;
};


//PA SIGNATURES
static async getSignaturesList(filters, nextpage, ) {
    var url = `api/v1/sandbox/statistics/signatures/packages/`
  
    const method = 'GET';
    const params = {};
    if (filters) {
        
        if (filters.signatures__signature_id) {
            params.signatures__signature_id = filters.signatures__signature_id;

        };
        if (filters.search) {
            params.search = filters.search;
        };
        if (filters.signatures__sha256) {
            params.signatures__sha256 = filters.signatures__sha256;
        };}
        if (nextpage) {
            params.page = nextpage;}
        // if(filters.ordering) params.order_by = filters.ordering
        // else params.order_by = '-created_at'
    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};
}
